@import 'src/styles/helpers.scss';

.parent {
  height: rem(910px);
  @media #{media("<")} {
    height: rem(630px);
  }
}

.footer {
  padding-top: rem(100px);
  @media #{media("<")} {
    padding-top: rem(32px);

    width: 100vw;
  }
  background-color: color(white);
}

.top {
  padding-bottom: rem(40px);
  @media #{media("<")} {
    padding-bottom: rem(32px);
  }
}

.row {
  display: flex;
  @media #{media("<")} {
    padding: 0 0 rem(25px);
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
  }
}

.col {

  flex-shrink: 0;

  &:nth-child(1) {
    width: calc(#{$col-part-d} * 9);
    @media #{media("<")} {
      width: auto;
    }
  }
  &:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(#{$col-part-d} * 7 - #{$col-gap-d});
    @media #{media("<")} {
      margin-bottom: rem(32px);
      
      order: -1;
      width: auto;
    }

    &.alt {
      @media #{media(">")} {
        align-items: flex-start;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}

.contacts {
  margin-bottom: rem(60px);
  @media #{media("<")} {
    margin-bottom: rem(20px);
  }
}

.wrapper {
  display: flex;

  &:not(:first-child) {
    margin-top: rem(20px);
  }
}

.contact {
  position: relative;

  @include fsl(100px, 90%);
  letter-spacing: -0.04em;
  @media #{media("<")} {
    @include fsl(32px);
    letter-spacing: -0.02em;
  }

  @include hoverLine;
}

.addresses {
  margin-top: rem(32px);
}

.address {

  &:not(:first-child) {
    margin-top: rem(32px);
    @media #{media("<")} {
      margin-top: rem(12px);
    }
  }
}

.heading {
  margin-bottom: rem(8px);
  @media #{media("<")} {
    @include fsl(14px);
    letter-spacing: -0.01em;
  }
  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
}

.label {
  color: color(gray);
  font-weight: 400;
  letter-spacing: -0.02em;
  @include fsl(18px, 130%);
  @media #{media("<")} {
    letter-spacing: -0.01em;
    @include fsl(14px);
  }
}

.inner {
  display: flex;
  justify-content: space-between;
}

.links {
  margin-bottom: rem(24px);

  display: flex;
}

.link {
  padding-bottom: rem(4px);

  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: rem(20px);
  }

  @include hoverLine;
}

.icon {
  margin-right: rem(4px);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(32px)};
    @media #{media("<")} {
      --size: #{rem(24px)};
    }
    width: var(--size);
    height: var(--size);
  }
}

.text {
  @include fsl(32px, 90%);
  letter-spacing: -0.04em;
  @media #{media("<")} {
    @include fsl(18px);
  }

  &.alt {
    @include hoverLine;
  }
}

.grid {
  display: grid;
  grid-template-columns: 26% 48% 26%;
  // grid-template-columns: 2fr 3fr 2fr;
  @media #{media("<")} {
    margin-top: rem(32px);
    grid-template-columns: repeat(2, 1fr);
  }

  &.default {
    margin-top: 0;
  }
}

.column {

  &.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.item {

  display: flex;

  &:not(:first-child) {
    margin-top: rem(12px);
    @media #{media("<")} {
      margin-top: rem(4px);
    }
  }
}

.ref {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      path:not([data-black]) {
        fill: color(white);
      }
  
      rect {
        &:nth-child(1) {
          fill: color(black);
        }
        &:nth-child(3) {
          stroke: color(black);
        }
      }
    }
  }
}

.img {
  margin-right: rem(12px);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(48px)};
    @media #{media("<")} {
      --size: #{rem(32px)};
    }
    width: var(--size);
    height: var(--size);

    path,rect {
      transition: $transition;
    }
  }
}

.caption {
  @include fsl(18px, 90%);
  letter-spacing: -0.02em;
  @media #{media("<")} {
    @include fsl(14px, 90%);
  }
}

.bot {
  padding-top: rem(40px);
  @media #{media("<")} {
    padding-top: rem(32px);
  }
  border-top: 1px solid color(borders);
}

.politics {
  flex-shrink: 0;
  width: 74%;
  display: flex;
  align-items: center;
  @media #{media("<")} {
    width: auto;
    display: block;
  }
}

.container {
  margin-right: rem(30px);
  // width: calc(#{$col-part-d} * 3 - #{$col-gap-d});
  @media #{media("<")} {
    margin-right: 0;
  }
}

.policy {
  font-weight: 400;
  letter-spacing: -0.02em;
  @include fsl(16px, 130%);
  color: color(black-alt);
  @include hoverLine;
}

.made {
  @media #{media("<")} {
    margin-top: rem(8px);
  }
  color: color(gray);
  font-weight: 400;
  letter-spacing: -0.02em;
  @include fsl(16px, 130%);
}

.logo {
  margin: rem(100px) 0 rem(40px);
  @media #{media("<")} {
    margin: rem(62px) 0 rem(20px);
    display: none;
  }
  display: flex;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
  }
}

.desktop {
  @media #{media("<")} {
    display: none;
  }
}

.mobile {
  @media #{media(">")} {
    display: none;
  }
}

.it {
  @media #{media("<")} {
    margin-top: rem(32px);
  }
}

.svg {
  margin-bottom: rem(8px);

  display: flex;
  align-items: center;

  svg {
    width: rem(130px);
    height: rem(24px);
  }
}

.accreditation {
  font-weight: 400;
  @include fsl(14px);
  @media #{media("<")} {
    @include fsl(16px);
  }
}

.bitrix {
  display: flex;
}