@import 'src/styles/helpers.scss';

.label {
  position: relative;
  z-index: 0;
  padding: 0 rem(28px);

  display: flex;
  align-items: center;
  height: rem(56px);
  @media #{media("<")} {
    justify-content: center;
    height: rem(48px);
    width: 100%;
  }

  cursor: pointer;
  border: 2px solid color(borders);
  border-radius: rem(12px);
  transition: $transition;

  &:hover {
    background-color: color(black-alt);
    border-color: color(black-alt);

    svg {
      path {
        fill: color(white);
      }
    }

    .text {
      color: color(white);
    }
  }
  
  &:active {
    background-color: color(blue);
    border-color: color(blue);

    svg {
      path {
        fill: color(white);
      }
    }

    .text {
      color: color(white);
    }
  }
}

.input {
  position: absolute;
  left: 0;

  opacity: 0;
  pointer-events: none;
}

.icon {
  margin-right: rem(10px);
  @media #{media("<")} {
    margin-right: rem(6px);
  }
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(20px)};
    @media #{media("<")} {
      --size: #{rem(16px)};
    }
    width: var(--size);
    height: var(--size);

    path {
      transition: $transition;
    }
  }
}

.text {
  transition: $transition;
  @include fsl(18px, 90%);
  letter-spacing: -0.02em;
  @media #{media("<")} {
    @include fsl(14px);
  }
}