@import 'src/styles/helpers.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1111;

  width: 100vw;
  // height: 100vh;

  pointer-events: none;

  &.active {
    pointer-events: all;

    .overlay {
      opacity: .3;
    }

    .container {
      transform: none;
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;

  background: #000000;
  opacity: 0;
  transition: $transition;
}

.container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: rem(40px);
  transform: translateX(100%);
  @media #{media("<")} {
    padding: rem(20px);

    width: calc(100% - #{rem(13px)});
  }
  width: rem(950px);
  height: 100%;

  background-color: color(white);
  transition: $transition;
  overflow-y: auto;
  scrollbar-track-color: #EEEEEE;
  scrollbar-darkshadow-color: #EEEEEE;
  scrollbar-3dlight-color: #EEEEEE;
  
  scrollbar-arrow-color: color(black-alt);
  
  scrollbar-highlight-color: color(black-alt);
  scrollbar-shadow-color: color(black-alt);
  scrollbar-face-color: color(black-alt);

  // firefox
  scrollbar-width: thin;
  scrollbar-color: color(black-alt) #EEEEEE;

  // chrome, edge, opera, safari
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #EEEEEE;
  }
  &::-webkit-scrollbar-thumb {
    // margin: 4px 0;
    background-color: color(black-alt);
  }

  &.active {
    .wrapper {
      opacity: 0;
    }
    .response {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.title {
  margin-bottom: rem(40px);
  @media #{media("<")} {
    margin-bottom: rem(20px);
  }
  text-align: left;
}

.text {
  margin-left: auto;

  width: rem(445px);
  @media #{media("<")} {
    width: auto;
    @include fsl(16px);

    br {
      display: none;
    }
  }
  font-weight: 400;
  @include fsl(20px, 140%);
  letter-spacing: -0.02em;
}

.form {
  margin-top: rem(60px);
  @media #{media("<")} {
    margin-top: rem(20px);
  }
}

.grid  {
  margin-bottom: rem(12px);
  @media #{media("<")} {
    margin-bottom: rem(4px);
    grid-template-columns: 1fr;
    row-gap: rem(4px);
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: rem(12px);
}

.input {
  padding: 0 rem(24px);
  
  width: 100%;
  height: rem(68px);
  @media #{media("<")} {
    height: rem(52px);
  }

  transition: $transition;
  color: color(black);
  font-weight: 400;
  @include fsl(20px, 120%);
  letter-spacing: -0.02em;
  border-radius: rem(12px);
  background-color: #F5F5F5;
  border: 1px solid transparent;
  @media #{media("<")} {
    @include fsl(16px);
  }

  &::placeholder {
    color: color(black);
    font-family: $ff-main;
  }

  &:hover {
    background-color: #EBEBEB;
  }

  &:focus {
    border-color: color(blue);
    background-color: color(white);
  }
  &[aria-invalid="true"] {
    background-color: #FFEAE8;
    border-color: #FFEAE8;
  }
}

.error {
  margin-top: rem(8px);

  letter-spacing: -0.01em;
  @include fsl(13px, 90%);
  color: color(error);
}

.textarea {
  padding: rem(20px) rem(24px);
  
  width: 100%;
  height: rem(200px);
  @media #{media("<")} {
    height: rem(140px);
  }
  resize: none;
  color: color(black);
  font-weight: 400;
  @include fsl(20px, 120%);
  letter-spacing: -0.02em;
  border-radius: rem(12px);
  background-color: #F5F5F5;
  border: none;
  @media #{media("<")} {
    @include fsl(16px);
  }

  &::placeholder {
    color: color(black);
    font-family: $ff-main;
  }
}

.inner {
  margin-top: rem(12px);
  @media #{media("<")} {
    margin-top: rem(8px);
    flex-direction: column;
    gap: rem(8px);
  }
  display: flex;
  justify-content: space-between;
}

.icon {
  --pad: #{rem(20px)};
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    --size: #{rem(48px)};
    @media #{media("<")} {
      --size: #{rem(32px)};
    }
    width: var(--size);
    height: var(--size);

    path {
      stroke: color(black);
    }
  }
}

.policy {
  margin-top: rem(20px);

  max-width: rem(322px);
  @media #{media("<")} {
    max-width: rem(265px);
    @include fsl(11px);
  }
  @include fsl(13px, 110%);
  letter-spacing: -0.01em;
  font-weight: 400;
}

.link {
  font-weight: 700;
}

.wrapper {
  transition: $transition;
}

.response {
  --pad: #{rem(40px)};
  @media #{media("<")} {
    --pad: #{rem(20px)};
  }
  position: absolute;
  left: var(--pad);
  top: var(--pad);
  z-index: 1;

  pointer-events: none;
  opacity: 0;
  transition: $transition;
}

.files {
  margin-right: rem(10px);
  @media #{media("<")} {
    margin-right: 0;
  }
}

.list {
  margin-bottom: rem(64px);
  @media #{media("<")} {
    margin-bottom: rem(20px);
  }
}

.file {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: rem(4px);
    @media #{media("<")} {
      margin-top: rem(8px);
    }
  }
}

.name {
  font-weight: 400;
  letter-spacing: -0.02em;
  @include fsl(20px, 140%);
  @media #{media("<")} {
    @include fsl(16px);
  }
}

.delete {
  margin-left: rem(4px);

  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    --size: #{rem(30px)};
    @media #{media("<")} {
      --size: #{rem(20px)};
    }

    width: var(--size);
    height: var(--size);

    path {
      stroke: color(black);
    }
  }
}

.btn {
  display: flex;
}

.sep {
  margin: 0 rem(3px);
  display: inline-block;
}

.error {
  margin-top: rem(15px);

  color: color(error);
  @include fsl(14px);
}

.reviews {
  margin-top: rem(100px);
  @media #{media("<")} {
    margin-top: rem(55px);
  }
}

.button {
  position: relative;
  z-index: 0;

  &.loading {
    .layout {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.layout {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(color(white), 0.5);
  transition: $transition;
  opacity: 0;
  pointer-events: none;
}

.loader {
  display: inline-block;
  position: relative;
  width: rem(70px);
  height: rem(70px);
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: rem(50px);
  height: rem(50px);
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}