@import 'src/styles/helpers.scss';

.div {
  transform: translateY(100px);
  opacity: 0;
  transition: 1s $transition-timing-function;

  &.animated {
    opacity: 1;
    transform: none;
  }
}