@import 'src/styles/helpers.scss';

.listing {
  padding-top: rem(156px);
  @media #{media("<")} {
    padding-top: rem(56px);
  }

  background-color: color(white);
}

.title {
  text-align: center;
  letter-spacing: -0.04em;
  @include fsl(200px, 70%);
  @media #{media("<")} {
    @include fsl(80px);
  }
}

.inner {
  margin-top: rem(60px);
  @media #{media("<")} {
    margin-top: rem(32px);
  }
}

.mobile {
  @media #{media(">")} {
    display: none;
  }
}

.logo {
  margin-bottom: rem(24px);

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: rem(145px);
  height: rem(14px);
  }
}