@import 'src/styles/helpers.scss';

.chat {
  position: fixed;
  right: rem(40px);
  bottom: rem(40px);
  z-index: 1000;
  @media #{media("<")} {
    right: rem(13px);
    bottom: rem(13px);
  }
}

.avatar {
  position: relative;
  z-index: 101;
  
  display: flex;
  align-items: center;

  border-radius: 24px;
  box-shadow: 0px 29px 32px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: $transition;
  @media #{media("<")} {
    border-radius: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    background: rgba(0, 0, 0, 0.20);
    border-radius: 24px;
    transition: $transition;
    opacity: 0;
    @media #{media("<")} {
      border-radius: 16px;
    }
  }

  &:hover {

    &::before {
      opacity: 1;
    }

    .pic {
      transform: scale(0.8);
    }
  }
}

.img {
  --size: #{100px};
  @media #{media("<")} {
    --size: #{70px};
  }

  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  overflow: hidden;
  @media #{media("<")} {
    border-radius: 16px;
  }
}

.hover {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;

  color: color(white);
  font-size: 16px;
  background: rgba(0, 0, 0, 0.40);
  border-radius: 24px;
  opacity: 0;
  transition: $transition;
  @media #{media("<")} {
    border-radius: 16px;
  }
}

.pic {
  position: absolute;
  z-index: 2;
  --size: #{82px};
  @media #{media("<")} {
    --size: #{55px};
  }

  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  border: 3px solid color(white);
  transition: $transition;

  img {
    width: 101%;
    height: 101%;

    border-radius: 50%;
  }
}

.icon {
  --size: 10px;
  --pad: 9px;
  @media #{media("<")} {
    --pad: 5px;
  }
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  z-index: 4;

  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;

  border-radius: 50%;
  background-color: #76FF46;
  animation: blinker 2s linear infinite;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  width: 100vw;
  height: 100vh;

  pointer-events: none;

  &.open {
    pointer-events: all;

    .overlay {
      opacity: 1;
    }

    .container {
      transform: none;
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  @media #{media("<")} {
    display: none;
  }

  background: rgba(0, 0, 0, 0.14);
  backdrop-filter: blur(8px);
  transition: $transition;
  opacity: 0;
}

.container {
  position: absolute;
  right: rem(8px);
  top: rem(8px);
  bottom: rem(8px);
  z-index: 1;
  transform: translateX(130%);
  @media #{media("<")} {
    top: 0;
    left: 0;
    bottom: 0;
    transorm: translateX(100%);
    
    width: 100vw;
  }

  width: rem(550px);
  display: flex;
  flex-direction: column;
  transition: $transition;
}

.top {
  padding: rem(32px);
  position: relative;
  z-index: 0;
  @media #{media("<")} {
    padding: rem(16px);

    border-radius: 0;
  }
  border-radius: rem(24px) rem(24px) 0 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: rem(-36px);
    z-index: -1;
    @media #{media("<")} {
      border-radius: 0;
    }
    border-radius: rem(24px) rem(24px) 0px 0px;
    background: linear-gradient(101deg, #040404 0.71%, #020EFB 63.7%, #38E7FF 111.63%);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media #{media("<")} {
    justify-content: center;
  }
}

.col {
  display: flex;
  align-items: center;

  &:nth-child(1) {
    @media #{media("<")} {
      flex-direction: column;
      
    }
  }

  &:nth-child(2) {
    @media #{media("<")} {
      position: absolute;
      left: rem(16px);
      top: rem(16px);
      z-index: 1;
    }
  }
}

.image {
  position: relative;
  z-index: 0;

  width: rem(72px);
  height: rem(72px);
  @media #{media("<")} {
    width: rem(48px);
    height: rem(48px);
  }
  display: flex;
  align-items: center;
  
  img {
    border: 3px solid color(white);
    border-radius: 50%;
    @media #{media("<")} {
      border-width: 2px;
    }
  }
}

.dot {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  width: rem(8px);
  height: rem(8px);
  @media #{media("<")} {
    width: rem(6px);
    height: rem(6px);
  }

  border-radius: 50%;
  background-color: #76FF46;
}

.content {
  margin-left: rem(16px);
  @media #{media("<")} {
    margin-left: 0;
    margin-top: rem(8px);
  }
}

.name {
  margin-bottom: rem(3px);
  @media #{media("<")} {
    margin-bottom: 0;
    @include fsl(13px, 130%);
    text-align: center;
    letter-spacing: 0;
  }
  font-weight: 700;
  color: color(white);
  @include fsl(20px, 90%);
  letter-spacing: -0.02em;
}

.post {
  @media #{media("<")} {
    @include fsl(14px);
    letter-spacing: 0;
    opacity: 1;
  }
  font-weight: 400;
  color: color(white);
  @include fsl(20px, 140%);
  opacity: 0.8;
  letter-spacing: -0.02em;
}

.btn {

  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: rem(8px);
    @media #{media("<")} {
      margin-left: rem(4px);
    }
  }

  svg {
    width: rem(72px);
    height: rem(72px);
    @media #{media("<")} {
      width: rem(40px);
      height: rem(40px);
    }
  }
}

.close {
  position: absolute;
  right: calc(100% + #{rem(24px)});
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  @media #{media("<")} {
    top: rem(16px);
    right: rem(16px);
    transform: none;

    width: auto;
    height: auto;

    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
  width: rem(100px);
  height: rem(100px);
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: rem(24px);
  background-color: color(white);
  box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.30);

  svg {
    width: rem(32px);
    height: rem(32px);
    @media #{media("<")} {
      path {
        fill: color(white);
      }
    }
  }
}

.body {
  position: relative;
  z-index: 2;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: color(white);
  border-radius: rem(24px);
  @media #{media("<")} {
    border-radius: rem(24px) rem(24px) 0 0;
  }
  overflow: hidden;
}


.messages {
  padding: rem(9px) rem(32px) rem(20px);
  @media #{media("<")} {
    padding: rem(16px) rem(16px) rem(20px);
  }
  height: 100%;

  overflow-y: auto;
  @include scrollbar-hidden;
}

.bot {
  padding-top: rem(32px);
  position: relative;
  z-index: 0;
  @media #{media("<")} {
    padding-top: rem(12px);
  }
  background-color: color(white);
  box-shadow: 0px -8px 32px rgba(0, 0, 0, 0.08);
  transition: $transition;

  &.loading {
    box-shadow: none;
    .wrapper {
      display: none;
      transform: translateY(-50px);
      opacity: 0;
      pointer-events: none;
    }
    // .loader {
    //   opacity: 1;
    //   transition-delay: 0.2s;
    // }
  }
}

.wrapper {
  transition: $transition;
}

.loader {
  position: absolute;
  left: rem(32px);
  top: 50%;
  transform: translateY(-50%);
  @media #{media("<")} {
    right: rem(13px);
    bottom: rem(13px);
  }
  width: 64px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  background: rgba(11, 11, 11, 0.30);
  box-shadow: 0px 0px 20px 0px rgba(27, 27, 27, 0.20);
  opacity: 0;
  pointer-events: none;
  transition: $transition;
}

.bubble {
  display: flex;
}

.circle {
  width: 5px;
  height: 5px;

  border-radius: 50%;
  background-color: color(white);
  animation: bounce 1.3s linear infinite;

  &:not(:first-child) {
    margin-left: rem(4px);
  }

  &:nth-child(1) {
    animation-delay: .6s;
  }
  &:nth-child(2) {
    animation-delay: .5s;
  }
  &:nth-child(1) {
    animation-delay: .8s;
  }
}

@keyframes bounce {
  30% {
    transform: translateY(-2px);
  }
  60% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0px);
    opacity: .5;
  }
}


.items {
  padding: 0 rem(32px) rem(64px);
  margin-left: rem(-8px);
  margin-top: rem(-8px);
  @media #{media("<")} {
    padding: 0 rem(13px) rem(36px);
  }
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-left: rem(8px);
  margin-top: rem(8px);
  padding: 0 rem(27px);
  @media #{media("<")} {
    padding: 0 rem(20px);

    height: rem(46px);
    @include fsl(16px);
  }
  height: rem(56px);
  display: flex;
  align-items: center;

  font-weight: 700;
  @include fsl(22px, 90%);
  transition: $transition;
  border-radius: rem(12px);
  border: 2px solid color(borders);
  cursor: pointer;

  &:hover {
    color: color(white);
    background-color: color(black-alt);
    border-color: color(black-alt);
  }
}

.progress {
  width: 100%;
  height: rem(12px);
  @media #{media("<")} {
    height: rem(8px);
  }
  background-color: color(blue);
  transition: $transition;
}

.form {
  padding: 0 rem(32px) rem(32px);
  @media #{media("<")} {
    padding: 0 rem(13px) rem(24px);
  }
}

.textarea {
  margin-bottom: rem(8px);
  padding: rem(15px) rem(24px);

  width: 100%;
  height: rem(140px);
  @media #{media("<")} {
    margin-bottom: rem(4px);
    padding: rem(16px);

    height: rem(100px);
    @include fsl(16px);
  }
  border-radius: rem(12px);
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: #F5F5F5;
  @include fsl(18px, 130%);
  resize: none;
  @include f-main(400);

  &::placeholder {
    color: color(black);
  }
  &[aria-invalid="true"] {
    background-color: #FFEAE8;
    border-color: #FFEAE8;
  }
}

.buttons {
  margin-top: rem(20px);
  @media #{media("<")} {
    margin-top: rem(4px);
  }
}

.button {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rem(60px);
  @media #{media("<")} {
    height: rem(48px);
    @include fsl(16px);
  }
  color: color(white);
  border-radius: rem(12px);
  background-color: color(black-alt);
  @include fsl(22px, 90%);
  letter-spacing: -0.02em;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: rem(8px);
  }

  &.outline {
    background-color: transparent;
    border: 1px solid #EAEAEA;
    color: color(black);
  }
}

.offset {

  &:not(:first-child) {
    margin-top: rem(8px);
  }
}

.error {
  margin-top: rem(8px);

  letter-spacing: -0.01em;
  @include fsl(13px, 90%);
  color: color(error);
}


.input {
  padding: 0 rem(24px);
  
  width: 100%;
  height: rem(60px);
  @media #{media("<")} {
    height: rem(48px);
    @include fsl(16px);
  }
  transition: $transition;
  color: color(black);
  font-weight: 400;
  @include fsl(18px, 120%);
  border-radius: rem(12px);
  background-color: #F5F5F5;
  border: 1px solid transparent;

  &::placeholder {
    color: color(black);
    font-family: $ff-main;
  }

  &:hover {
    background-color: #EBEBEB;
  }

  &:focus {
    border-color: color(blue);
    background-color: color(white);
  }
  &[aria-invalid="true"] {
    background-color: #FFEAE8;
    border-color: #FFEAE8;
  }
}
