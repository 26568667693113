@import 'src/styles/helpers.scss';

.decor {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  pointer-events: none;

  &.light {
    .item {
      border-color: color(borders);
    }
  }
}

.item {
  --size: #{rem(40px)};
  position: absolute;
  @media #{media("<")} {
    --size: #{rem(31px)};
  }
  width: var(--size);
  height: var(--size);

  border: 2px solid #292929;
  @media #{media("<")} {
    border-width: 1px;
  }

  &:nth-child(1) {
    left: 0;
    top: 0;

    border-bottom: none;
    border-right: none;
    border-top-left-radius: rem(16px);
  }
  &:nth-child(2) {
    right: 0;
    top: 0;

    border-left: none;
    border-bottom: none;
    border-top-right-radius: rem(16px);
  }
  &:nth-child(3) {
    right: 0;
    bottom: 0;

    border-top: none;
    border-left: none;
    border-bottom-right-radius: rem(16px);
  }
  &:nth-child(4) {
    left: 0;
    bottom: 0;

    border-top: none;
    border-right: none;
    border-bottom-left-radius: rem(16px);
  }
}