@import 'src/styles/helpers.scss';

.item {
  width: rem(283px);
  height: rem(44px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{media("<")} {
    width: rem(140px);
    height: rem(36px);
  }

  opacity: 0.4;

  &.big {
    height: rem(74px);
    @media #{media("<")} {
      height: rem(55px);
    }
  }
}