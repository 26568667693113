@import 'src/styles/helpers.scss';

.title {
  text-align: center;
  @include fsl(100px, 90%);
  letter-spacing: -0.05em;
  @media #{media("<")} {
    @include fsl(30px);
    letter-spacing: -0.04em;
  }
}