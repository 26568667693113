@import 'src/styles/helpers.scss';

.button {
  position: relative;
  z-index: 0;
  padding: rem(20px);
  @media #{media("<")} {
    padding: rem(12px);
  }

  width: rem(210px);
  height: rem(100px);
  @media #{media("<")} {
    width: 100%;
    height: rem(69px);
  }
  display: flex;
  justify-content: space-between;

  @include f-main;
  transition: $transition;
  border-radius: rem(12px);
  background-color: color(blue);
  cursor: pointer;
  overflow: hidden;
  border: none;


  &:hover {
    background-color: color(black-alt);

    .shape {
      top: 0;
      left: 0;
      background-color: color(blue);
      filter: blur(rem(32px));
    }
  }

  &.small {
    @media #{media(">")} {
      height: rem(56px);
      align-items: center;
      justify-content: center;
  
      &::after {
        top: rem(10px);
      }
  
      .text {
        margin-right: rem(10px);
      }

      .icon {
        height: auto;
      }
    }
  }

  &.big {
    width: rem(282px);
    @media #{media("<")} {
      width: 100%;
    }
  }

  &.chat {
    width: 100%;

    @media #{media("<")} {
      height: rem(44px);
      align-items: center;
      justify-content: center;
    }

    .text {
      margin-right: 0;
    }

    .icon {
      display: none;
    }
  }
}

.shape {
  --size: #{rem(156px)};
  position: absolute;
  top: rem(50px);
  left: rem(110px);
  z-index: -1;
  @media #{media("<")} {
    top: rem(30px);
    left: auto;
    right: rem(-35px);
  }

  width: var(--size);
  height: var(--size);

  transition: .7s $transition-timing-function;
  border-radius: 50%;
  background-color: color(black);
  pointer-events: none;
  opacity: 0.7;
  filter: blur(rem(47px));
}

.text {
  pointer-events: none;
  color: color(white);
  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
  @media #{media("<")} {
    @include fsl(16px);
  }
}

.icon {
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  svg {
    --size: #{rem(20px)};

    width: var(--size);
    height: var(--size);
  }
}