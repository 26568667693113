@import 'src/styles/helpers.scss';

.container {
  // position: relative;
  // z-index: 0;

  height: rem(720px);
  @media #{media("<")} {
    height: rem(350px);
  }
  @media #{media("<", m)} {
    height: rem(350px);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{media("<")} {
    display: block;
  }

  border-radius: rem(20px);
  @media #{media("<")} {
    border-radius: rem(16px);
  }
  overflow: hidden;
  // background-color: color(black-alt);
}

.parallax {
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
    @media #{media("<")} {
      transition: $transition;
    }
  }
}

.img {
  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  @media #{media("<")} {
    height: rem(500px);
  }
  @media #{media("<", m)} {
    height: rem(380px);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    @media #{media("<")} {
      display: none;
    }
    width: 100%;
    height: 100%;

    background: rgba(#000, 0.2);
    // background-color: #00000080;
  }
}

.video {
  width: 100%;
  display: block;
  @media #{media("<")} {
    height: 100%;
    object-fit: cover;
  }
}

.parent {
  position: relative;
  z-index: 0;

  @media #{media("<")} {
    display: contents;
  }

  cursor: none;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  @media #{media("<")} {
    position: static;
    margin-top: rem(12px);

    pointer-events: all;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  
  pointer-events: none;
  transition: $transition;
  // transition: 0.3s $transition-timing-function;
}

.cursor {
  position: absolute;
  @media #{media("<")} {
    position: static;

    width: auto;
    height: auto;
    transform: none;
    opacity: 1;
  }
  width: rem(600px);
  height: rem(260px);
  display: flex;
  align-items: center;
  justify-content: center;
  
  transform:  scale(0);
  opacity: 0;
  transition: 0.5s cubic-bezier(0.22, 1, 0.36, 1);
  
  &.active {
    opacity: 1;
    transform:  scale(1);
  }
}

.decor {
  --size: #{rem(20px)};
  position: absolute;
  @media #{media("<")} {
    display: none;
  }
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;

  &:nth-child(1) {
    left: 0;
    top: 0;
  }
  &:nth-child(2) {
    right: 0;
    top: 0;

    transform: rotate(90deg);
  }
  &:nth-child(3) {
    right: 0;
    bottom: 0;

    transform: rotate(180deg);
  }
  &:nth-child(4) {
    left: 0;
    bottom: 0;

    transform: rotate(-90deg);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.inner {
  padding: rem(20px);
  @media #{media("<")} {
    padding: 0;

    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0;
  }
  width: rem(220px);

  border-radius: rem(12px);
  background-color: color(white);
  cursor: pointer;
}

.text {
  letter-spacing: -0.02em;
  @include fsl(18px, 100%);
  @media #{media("<")} {
    @include fsl(24px);

    br {
      display: none;
    }
  }
}

.icon {
  @media #{media("<")} {
    margin-left: rem(8px);
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    --size: #{rem(24px)};
    @media #{media("<")} {
      --size: #{rem(20px)};
    }
    width: var(--size);
    height: var(--size);
  }
}