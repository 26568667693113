@import 'src/styles/helpers.scss';

.header {
  --pad: #{rem(40px)};
  @media #{media("<")} {
    --pad: #{rem(14px)};
  }
  position: fixed;
  left: 0;
  // left: var(--pad);
  // right: var(--pad);
  top: var(--pad);
  z-index: 999;
  padding: 0 var(--pad);

  width: 100vw;
  
  pointer-events: none;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{media("<")} {
    display: block;
  }
}

.col {
  pointer-events: all;
  &:nth-child(1),
  &:nth-child(3) {
    @media #{media("<")} {
      display: none;
    }
  }

  &:nth-child(2) {
    @media #{media("<")} {
      display: flex;
    }
  }

  transition: $transition;

  &.hide {
    opacity: 0;
  }
}

.logo {
  --pad: #{rem(40px)};
  @media #{media("<")} {
    display: none;
  }
  position: fixed;
  z-index: 1001;
  top: rem(57px);
  left: var(--pad);

  display: flex;
  align-items: center;
  justify-content: center;

  mix-blend-mode: difference;
  transition: $transition;

  svg {
    width: rem(217px);
    height: rem(23px);

    path {
      fill: color(white);
    }
  }

  &.hide {
    opacity: 0;
  }
  &.hidden {
    position: static;
    visibility: hidden;
  }
}

.link {
  padding: 0 rem(28px);

  display: flex;
  align-items: center;
  height: rem(56px);
  @media #{media("<")} {
    padding: 0;

    width: rem(40px);
    height: rem(40px);
    justify-content: center;

    border-width: 1px;
  }
  border: 2px solid color(borders);
  border-radius: rem(12px);
  background-color: color(white);
  transition: $transition;

  &:hover {
    background-color: color(black-alt);
    border-color: color(black-alt);

    .icon svg {
      path {
        fill: color(white);
      }
    }

    .text {
      color: color(white);
    }
  }
}

.icon {
  margin-right: rem(10px);
  @media #{media("<")} {
    margin-right: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(20px)};

    flex-shrink: 0;
    width: var(--size);
    height: var(--size);

    transform: rotate(180deg);

    path {
      transition: $transition;
      fill: color(black);
    }
  }
}

.text {
  transition: $transition;
  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
  @media #{media("<")} {
    display: none;
  }
}

.mobile {
  @media #{media(">")} {
    display: none;
  }
}