@import 'src/styles/helpers.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  cursor: none !important;
  @media #{media("<")} {
    padding: 0 rem(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000000, 0.7);
  }

  background-color: color(black-alt);
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 1;

  //   width: 100%;
  //   height: 100%;

  //   background-color: rgba(#00000080, 0.5);
  // }
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{media("<")} {
    display: none;
  }
}

.cursor {
  position: absolute;

  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;

  svg {
    --size: #{rem(100px)};

    width: var(--size);
    height: var(--size);
  }
}

.video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media #{media("<")} {
    position: static;
    transform: none;
    width: 100%;
    min-width: auto;
    min-height: auto;
  }
  min-width: 100vw;
  min-height: 100vh;
}

.icon {
  @media #{media(">")} {
    display: none;
  }
  position: absolute;
  right: rem(15px);
  top: rem(15px);

  display: flex;
  align-items: center;

  svg {
    --size: #{rem(40px)};

    width: var(--size);
    height: var(--size);
  }
}

.frame {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
  }
}