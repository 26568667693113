@import 'src/styles/helpers.scss';

.wrapper {
  margin-top: rem(20px);
}

.heading {
  margin: 0 auto;
  max-width: rem(1030px);
  @media #{media("<")} {
    max-width: none;
  }
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 400;
  @include fsl(48px, 120%);
  @media #{media("<")} {
    @include fsl(20px);
  }
}

.inner {
  margin-top: rem(80px);
  @media #{media("<")} {
    margin-top: rem(32px);
  }
}

.row {
  display: flex;
  justify-content: space-around;
  @media #{media("<")} {
    gap: rem(15px);
    justify-content: center;
  }
}

.col {
  &:nth-child(1),
  &:nth-child(3) {
    @media #{media(">")} {
      width: calc(#{$col-part-d} * 4);
    }
  }
  &:nth-child(2) {
    @media #{media("<")} {
      display: none;
    }
  }
}

.card {
  width: rem(910px);
  @media #{media("<")} {
    width: auto;
  }

  &.mobile {
    margin-bottom: rem(32px);
    @media #{media(">")} {
      display: none;
    }
  }
}

.link {
  height: rem(200px);
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{media("<")} {
    height: rem(80px);
    border-radius: rem(16px);
  }
  border-radius: rem(40px);
  border: 2px solid color(borders);
  transition: $transition;

  &:hover {
    background-color: color(black-alt);
    border-color: color(black-alt);

    .text {
      color: color(white);
    }

    .icon {
      svg {
        path {
          fill: color(white);
        }
      }
    }
  }
  &:active {
    .text {
      color: color(blue);
    }

    .icon {
      svg {
        path {
          fill: color(blue);
        }
      }
    }
  }
}

.text {
  color: #A6A6A6;
  transition: $transition;
  letter-spacing: -0.05em;
  @include fsl(68px, 90%);
  @media #{media("<")} {
    // max-width: rem(105px);
    @include fsl(24px);
  }
}

.icon {
  margin-left: rem(16px);
  @media #{media("<")} {
    margin-left: rem(8px);
  }

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(24px)};
    margin-top: rem(5px);
    @media #{media("<")} {
      --size: #{rem(16px)};
      margin-top: 0;
    }
    width: var(--size);
    height: var(--size);

    path {
      transition: $transition;
      fill: #A6A6A6;
    }
  }
}

.title {
  @media #{media("<")} {
    br {
      display: none;
    }
  }
}