@import 'src/styles/helpers.scss';

.wrapper {
  padding: rem(10px);
  margin: rem(-10px);

  overflow: hidden;
}

.item {
  will-change: auto;
  transition: 1s $transition-timing-function;
  opacity: 0;
  transform: scaleX(0.9) skewY(8deg) translateY(210px);

  &.animated {
    // will-change: transform;
    opacity: 1;
    transform: none;
  }
}