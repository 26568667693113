@use "sass:map";
@use "sass:string";
@use "sass:math";

@import './mixins.scss';
@import './functions.scss';

// fonts
$fs: 16px;

$ff-main: 'PT Root UI';

// media breakpoints
$breakpoints: (
  xxl: 1920px,
  xl: 1680px,
  ll: 1440px,
  l: 1366px,
  d: 1024px,
  // t: 1024px,
  m: 768px,
  s: 576px,
  xs: 375px,
  xxs: 320px,
);

// variables
$colors: (
  black: #0F0F0F,
  black-alt: #0B0B0B,
  black-third: #333333,
  white: #fff,
  gray: #767676,
  gray-second: #B3B3B3,
  gray-alt: #BBBBBB,
  borders: #EAEAEA,
  blue: #0000F2,
  error: #FF6F61,
);

@function color($key) {
  @return map.get($colors, $key);
}

// grid columns
$col-count: 16;

$col-pad-m: rem(13px);
$col-pad-d: rem(40px);

$col-gap-d: rem(20px);
$col-gap-m: 8px;

$col-width-m: '((100vw - (#{$col-pad-m} * 2) - (#{$col-gap-m} * #{$col-count - 1})) / #{$col-count})';
$col-width-d: '((100vw - (#{$col-pad-d} * 2) - (#{$col-gap-d} * #{$col-count - 1})) / #{$col-count})';

$col-part-m: '(#{$col-width-m} + #{$col-gap-m})';
$col-part-d: '(#{$col-width-d} + #{$col-gap-d})';


$transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
$transition-duration: .5s;
$transition: $transition-duration $transition-timing-function;