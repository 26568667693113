@import 'src/styles/helpers.scss';

.awards {
  position: relative;
  z-index: 0;
  padding: rem(62px) 0 rem(100px);
  @media #{media("<")} {
    padding: rem(20px) 0 rem(50px);
  }

  background-color: #101010;
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  max-width: rem(500px);
  @media #{media("<", m)} {
    max-width: rem(309px);
  }
}

.count {
  text-align: center;
  color: color(white);
  letter-spacing: -0.06em;
  @include fsl(300px, 90%);
  @media #{media("<")} {
    
    @include fsl(130px);
  }

  &.blue {
    & span, div {

      background: linear-gradient(241.19deg, #08089B 9.33%, #020EFB 46.92%, #38E7FF 69.8%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  &.green {
    & span, div {
      background: linear-gradient(236.08deg, #73F9FF 29.7%, #FEFFFF 51.77%, #FBFF37 79.04%), #FFFFFF;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.caption {
  text-align: center;
  letter-spacing: -0.04em;
  color: color(gray);
  @include fsl(40px, 90%);
  @media #{media("<")} {
    @include fsl(24px);
    letter-spacing: -0.02em;
  }
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  display: flex;
  align-items: center;
}

.grid {
  margin: rem(60px) auto 0;
  @media #{media("<")} {
    margin: rem(32px) 0 0;
    width: auto;
    grid-template-columns: repeat(2, 1fr);
    column-gap: rem(11px);
    row-gap: 0;
  }
  width: rem(1025px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(22px);
}

.item {
  position: relative;
  z-index: 0;
  @media #{media("<")} {
    margin-top: -1px;
    height: rem(209px);
  }
  height: rem(224px);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.alt {
    justify-content: center;
  }
}

.icon {
  
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    --size: #{rem(120px)};

    width: var(--size);
    height: var(--size);
  }
}

.name {
  margin-bottom: rem(12px);
  @media #{media("<")} {
    margin-bottom: rem(8px);
    letter-spacing: -0.01em;
    @include fsl(14px);
  }
  letter-spacing: -0.02em;
  color: color(gray);
  @include fsl(18px, 120%);
}

.num {
  color: color(white);
  letter-spacing: -0.05em;
  @include fsl(48px, 90%);
  @media #{media("<")} {
    @include fsl(40px);
  }
  span {
    margin-right: rem(5px);
    display: inline-block;
    transform: translateY(rem(-4px));
  }
}

.img {
  position: absolute;
  z-index: -1;

  display: flex;
  align-items: center;

  &.bot {
    left: rem(-312px);
    bottom: 0;
    @media #{media("<")} {
      // left: rem(-87px);
      right: rem(-100px);
      left: auto;
      bottom: auto;
      top: rem(863px);
      width: rem(374px);
      height: rem(374px);
    }
    width: rem(1346px);
    height: rem(1346px);
  }
  &.top {
    left: rem(-356px);
    top: rem(-1056px);
    @media #{media("<")} {
      left: rem(-87px);
      top: rem(-150px);
      width: rem(374px);
      height: rem(374px);
    }
    width: rem(1352px);
    height: rem(1352px);
  }

  &.right {
    right: rem(-305px);
    top: rem(850px);
    @media #{media("<")} {
      // right: -1px;
      // bottom: rem(-96px);
      // width: rem(376px);
      // height: rem(376px);
      display: none;
    }
    width: rem(1352px);
    height: rem(1352px);
  }
}

.pic {
  transition: $transition;
  overflow: visible !important;


  & > div {
    transition: $transition;
    will-change: transform;
  }

  &.bot {
    width: rem(1346px);
    height: rem(1346px);
    @media #{media("<")} {
      margin-top: 0;
      width: rem(374px);
      height: rem(374px);
    }
  }

  &.top {
    width: rem(1352px);
    height: rem(1352px);
    @media #{media("<")} {
      width: rem(376px);
      height: rem(376px);
    }

    img {
      height: 99%;
    }
  }
  &.right {
    width: rem(1352px);
    height: rem(1352px);
    @media #{media("<")} {
      width: rem(376px);
      height: rem(376px);
    }

    img {
      height: 99%;
    }
  }
}

.inner {
  margin-top: rem(80px);
  @media #{media("<")} {
    margin-top: rem(32px);
  }
  display: flex;
  justify-content: center;
}

.text {
  margin-top: rem(12px);
  @media #{media("<")} {
    margin-top: rem(8px);
    max-width: rem(163px);
  }
  max-width: rem(446px);

  text-align: center;
  color: color(gray);
  @include fsl(40px, 90%);
  letter-spacing: -0.04em;
  @media #{media("<")} {
    @include fsl(18px);
  }
  span {
    color: color(white);
  }
}

.number {
  text-align: center;
  @include fsl(48px, 90%);
  color: color(white);
  @media #{media("<")} {
    @include fsl(40px);
  }
}
.place {
  @include fsl(18px, 130%);
  letter-spacing: -0.02em;
  text-align: center;
  @media #{media("<")} {
    letter-spacing: -0.01em;
    @include fsl(14px);
  }
}

.desc {
  margin-top: rem(13px);
  @media #{media("<")} {
    margin-top: rem(18px);
    max-width: rem(141px);
  }
  max-width: rem(267px);
  color: color(gray);
  letter-spacing: -0.02em;
  text-align: center;
  // @include fsl(18px, 130%);
  font-size: 1.25rem;
  @media #{media("<")} {
    @include fsl(14px);
    letter-spacing: -0.01em;
  }
  &.alt {
    @media #{media("<")} {
      margin-top: rem(8px);
    }
    max-width: none;
  }
}

.bot {
  margin-top: rem(60px);
  @media #{media("<")} {
    margin-top: rem(32px);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.runet {
  display: flex;
  align-items: center;

  svg {
    width: rem(33px);
    height: rem(46px);
    @media #{media("<")} {
      width: rem(25px);
      height: rem(35px);
    }
  }
}

.animation {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
}