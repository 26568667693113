@import 'src/styles/helpers.scss';

.header {
  position: fixed;
  left: 0;
  top: -100%;
  // top: 0;
  z-index: 1000;

  width: 100vw;

  transition: border-color $transition, top 1s $transition-timing-function, transform $transition;
  border-top: 4px solid color(black-alt);
  @media #{media("<")} {
    border-top: none;

    transform: translateY(rem(-429px));

    &.active {
      transform: none;
    }
  }

  &.active {
    border-color: #333333;
  }

  &.loaded {
    top: 0;
  }

  &:hover:not(.active) {
    border-color: #333333;
  }
}

.menu {
  margin: 0 auto;
  position: relative;
  z-index: 0;

  width: rem(176px);
  height: rem(48px);
  @media #{media("<")} {
    width: rem(120px);
    height: rem(31px);
    transform: translateY(-1px);
  }

  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
    transform: translateX(-50%);

    width: rem(216px);
    // width: rem(176px);
    height: rem(48px);
    @media #{media("<")} {
      top: 0;
      width: rem(156px);
      height: rem(31px);
    }

    background-image: url("data:image/svg+xml,%3Csvg width='216' height='48' viewBox='0 0 216 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H216H211.455C200.409 0 191.455 8.9543 191.455 20V33C191.455 41.2843 184.739 48 176.455 48H39.5455C31.2612 48 24.5455 41.2843 24.5455 33V20C24.5455 8.95431 15.5911 0 4.54545 0H0Z' fill='%230B0B0B'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{media("<")} {
      background-image: url("data:image/svg+xml,%3Csvg width='154' height='31' viewBox='0 0 154 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H154H153.722C144.211 0 136.5 7.71065 136.5 17.2222C136.5 24.8315 130.331 31 122.722 31H31.2778C23.6685 31 17.5 24.8315 17.5 17.2222C17.5 7.71065 9.78935 0 0.277779 0H0Z' fill='%230B0B0B'/%3E%3C/svg%3E%0A");
      background-position: top;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
    transform: translateX(-50%);

    width: rem(216px);
    // width: rem(176px);
    height: rem(48px);
    @media #{media("<")} {
      top: 0;
      width: rem(156px);
      height: rem(31px);
    }

    opacity: 0;
    transition: $transition;
    background-image: url("data:image/svg+xml,%3Csvg width='216' height='48' viewBox='0 0 216 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H216H211.455C200.409 0 191.455 8.9543 191.455 20V33C191.455 41.2843 184.739 48 176.455 48H39.5455C31.2612 48 24.5455 41.2843 24.5455 33V20C24.5455 8.95431 15.5911 0 4.54545 0H0Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{media("<")} {
      background-image: url("data:image/svg+xml,%3Csvg width='154' height='31' viewBox='0 0 154 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H154H153.722C144.211 0 136.5 7.71065 136.5 17.2222C136.5 24.8315 130.331 31 122.722 31H31.2778C23.6685 31 17.5 24.8315 17.5 17.2222C17.5 7.71065 9.78935 0 0.277779 0H0Z' fill='%230B0B0B'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &.active {

    &::after {
      opacity: 1;
    }
    .icon {
      opacity: 1;
      pointer-events: all;
    }

    .inner {
      opacity: 0;
    }
  }

  &:hover {

    &::after {
      opacity: 1;
    }

    .burger {
      height: rem(14px);
    }
  }
}

.icon {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  opacity: 0;
  transition: $transition;

  svg {
    --size: #{rem(30px)};

    width: var(--size);
    height: var(--size);
  }
}

.inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
}

.text {
  margin-right: rem(12px);
  color: color(white);
  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
  @media #{media("<")} {
    @include fsl(14px);
    letter-spacing: 0;
  }
}

.burger {
  position: relative;
  z-index: 0;

  width: rem(32px);
  height: rem(8px);

  transition: $transition;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    height: rem(2px);

    background-color: color(white);
  }

  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.container {
  position: absolute;
  top: 0;
  left: rem(40px);
  right: rem(40px);
  z-index: 1;
  padding-top: rem(62px);
  @media #{media("<")} {
    position: static;
    padding: rem(20px) rem(13px) rem(32px);

    pointer-events: all;
    background-color: color(black-alt);
    transform: none;
  }
  transform: translateY(-110%);
  transition: $transition;
  pointer-events: none;

  &.active {
    transform: none;
  }
}

.row {
  display: flex;
  align-items: center;
  pointer-events: all;
  @media #{media("<")} {
    align-items: flex-start;
    flex-direction: column;
  }
  // justify-content: space-between;
}

.col {

  &:nth-child(2) {
    margin-left: rem(280px);
    @media #{media("<")} {
      margin-left: 0;
      margin-bottom: rem(32px);
      order: -1;
    }
  }
  &:nth-child(3) {
    margin-left: auto;
    @media #{media("<")} {
      margin-top: rem(32px);
      margin-left: 0;
      
      width: 100%;
    }
  }
}

.list {
  display: flex;
  
  &.block {
    @media #{media("<")} {
      display: block;
    }
  }
}

.item {

  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-left: rem(8px);
    @media #{media("<")} {
      margin-left: rem(22px);
    }
  }

  svg {
    --size: #{rem(52px)};

    width: var(--size);
    height: var(--size);

    overflow: visible;

    rect {
      transition: $transition;
      fill: #222222;
    }
  }


  &:hover {
    svg {
      rect {
        fill: color(black-third);
      }
    }
  }
}

.link {
  padding: rem(18px) rem(32px);
  @media #{media("<")} {
    padding: 0;
    display: block;

    @include fsl(32px);
    border-radius: 0;
  }
  color: color(white);
  border-radius: rem(12px);
  background-color: color(black-alt);
  cursor: pointer;
  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
  transition: $transition;

  &:not(:first-child) {
    margin-left: rem(8px);
    @media #{media("<")} {
      margin-left: 0;
      margin-top: rem(12px);
    }
  }

  &:hover {
    @media #{media(">")} {
      background-color: color(black-third);
    }
  }
}