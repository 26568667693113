@import 'src/styles/helpers.scss';

.button {
  width: rem(312px);
  @media #{media("<")} {
    width: auto;
  }
}

button.btn {
  padding: 0;

  width: 100%;
  height: rem(56px);
  align-items: center;
  justify-content: center;

  div:nth-child(3) {
    margin-left: rem(10px);
    align-items: center;
  }
}

.logo {
  display: none;
  @media #{media("<")} {
    margin-bottom: rem(24px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: rem(145px);
    height: rem(14px);
  }
}