@import 'src/styles/helpers.scss';

.item {

  &:not(:first-child) {
    margin-top: rem(16px);
    @media #{media("<")} {
      margin-top: rem(8px);
    }
  }
}

.container {

  &.user {
    .message {
      white-space: pre;
      text-align: right;
      border-radius: rem(16px) rem(16px) 0px rem(16px);
    }

    .info {
      justify-content: flex-end;
    }

    .wrapper {
      justify-content: flex-end;
    }
  }

  &.active {
    .message {
      max-width: rem(476px);
      @media #{media("<")} {
        // max-width: rem(273px);
        max-width: none;

        @include fsl(16px);
      }
      color: color(white);
      background-color: color(blue);
      // background: linear-gradient(101deg, #040404 0.71%, #020EFB 63.7%, #38E7FF 111.63%);
      @include fsl(22px);
      font-weight: 700;
    }
  }
}

.wrapper {
  display: flex;
  // justify-content: flex-end;
}

.message {
  padding: rem(16px);

  max-width: rem(400px);
  @media #{media("<")} {
    max-width: rem(273px);
    @include fsl(16px);
  }
  font-weight: 400;
  white-space: pre-line;
  @include fsl(18px, 130%);
  border-radius: rem(16px) rem(16px) rem(16px) 0px;
  background: #F5F5F5;
}

.video {
  margin-bottom: rem(12px);
  position: relative;
  z-index: 0;

  width: rem(265px);
  height: rem(265px);
  display: flex;
  align-items: center;
  @media #{media("<")} {
    width: rem(211px);
    height: rem(211px);
  }

  border-radius: 50%;
  overflow: hidden;
  // border: 2px so;
}

.player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: $transition;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    width: rem(40px);
    height: rem(40px);
    @media #{media("<")} {
      width: rem(32px);
      height: rem(32px);
    }
  }
}

.timer {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: rem(16px);
  transform: translateX(-50%);
  padding: rem(4px) rem(8px);
  @media #{media("<")} {
    bottom: rem(13px);
    padding: rem(2px) rem(6px);
    @include fsl(12px);
  }
  font-weight: 400;
  @include fsl(15px, 120%);
  border-radius: rem(8px);
  color: color(white);
  background-color: rgba(color(black), 0.4);
}

.info {
  margin-top: rem(8px);

  display: flex;
  align-items: center;
}

.text {
  font-weight: 500;
  @include fsl(14px, 85%);
  color: color(gray-alt);
  @media #{media("<")} {
    @include fsl(11px, 110%);
  }
}

.icon {
  margin-left: rem(4px);

  display: flex;
  align-items: center;

  svg {
    width: rem(16px);
    height: rem(16px);
  }
}


