@import 'src/styles/helpers.scss';

.section {
  position: relative;
  z-index: 0;
  padding: rem(40px) 0;
  @media #{media("<")} {
    padding: rem(16px) 0 rem(25px);
  }

  border-bottom: 1px solid color(borders);
}

.row {
  display: flex;
  justify-content: space-between;
  @media #{media("<")} {
    flex-direction: column;
    align-items: center;
  }
}

.col {

  &:nth-child(3) {
    @media #{media("<")} {
      // padding-right: rem(80px);
      width: 100%;
    }
  }

  &:not(:first-child) {
    @media #{media("<")} {
      margin-top: rem(32px);
    }
  }
}

.logo {
  display: flex;
  align-items: center;

  svg {
    width: rem(220px);
    @media #{media("<")} {
      width: rem(145px);
    }
    height: auto;
  }
}

.title {
  
}

.wrapper {
  padding: 0 rem(15px) rem(25px);
  margin: rem(10px) 0 rem(-25px);
  position: relative;
  z-index: 0;
  @media #{media("<")} {
    padding: 0 rem(15px) rem(15px);
    margin: rem(10px) 0 rem(-15px);
  }

  display: flex;
  justify-content: center;
  width: rem(1031px);
  height: rem(120px);
  @media #{media("<")} {
    width: rem(331px);
    height: rem(40px);
  }
  
  overflow: hidden;
}

.test {
  margin-left: rem(22px);
  @media #{media("<")} {
    margin-left: rem(8px);
  }
  display: inline-block;
  color: color(gray-alt);
}

.span {
  position: absolute;
  // left: 50%;
  top: rem(10px);
  @media #{media("<")} {
    top: 0;
  }
  // transform: translateX(-50%);

  display: flex;
  justify-content: center;

  white-space: nowrap;

  &.static {
    position: static;
  }
}

.list {
  margin-top: rem(46px);

  display: flex;
  justify-content: center;
  @media #{media("<")} {
    display: none;
  }
  counter-reset: index;
}

.item {
  counter-increment: index;

  width: calc(#{$col-part-d} * 3);
  display: flex;
  justify-content: center;

  letter-spacing: -0.02em;
  @include fsl(18px, 90%);
}

.text {
  &::before {
    content: "0" counter(index);
    margin-bottom: rem(12px);

    display: block;

    text-align: center;
    color: color(gray-alt);
  }
  
  @include hoverLine;
}

