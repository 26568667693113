@import 'src/styles/helpers.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: rem(60px);
  column-gap: rem(20px);
  @media #{media("<")} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: rem(12px);
    row-gap: rem(40px);
  }
}

.item {
  display: flex;
  flex-direction: column;
}

.link {
  position: relative;
  z-index: 0;

  height: 100%;
  display: block;
  @media #{media("<")} {
    border-radius: rem(12px);
  }
  border-radius: rem(20px);
  border: 1px solid color(borders);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    opacity: 0;
    background-color: rgba(color(black-alt), 0.16);
    transition: $transition;
    pointer-events: none;
  }

  &:hover {

    &::before {
      opacity: 1;
    }

    svg {
      rect {
        fill: color(blue);
      }
    }
  }
}

.container {
  padding: rem(12px) rem(10px);
  @media #{media("<")} {
    padding: rem(6px);
  }

  display: flex;
  align-items: center;
}

.icon {
  --pad: #{rem(20px)};
  --size: #{rem(48px)};
  @media #{media("<")} {
    --pad: #{rem(10px)};
    --size: #{rem(32px)};
  }
  position: absolute;
  z-index: 2;
  bottom: var(--pad);
  left: var(--pad);

  display: flex;
  align-items: center;
  
  svg {
    width: var(--size);
    height: var(--size);

    rect {
      transition: $transition;
    }
  }
}

.name {
  margin-top: rem(15px);
  @media #{media("<")} {
    margin-top: rem(8px);
    @include fsl(16px);
  }
  letter-spacing: -0.02em;
  @include fsl(24px, 90%);
}