@import 'src/styles/helpers.scss';

.num {
  white-space: nowrap;
  @media #{media("<")} {
    @include fsl(60px);
  }
}

.text {
  margin-top: rem(16px);
  
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.02em;
  @include fsl(24px, 120%);
  @media #{media("<")} {
    margin-top: rem(4px);
    max-width: rem(150px);
    @include fsl(20px);
    letter-spacing: -0.04em;
  }
}