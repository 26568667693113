@import 'src/styles/helpers.scss';

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{media("<")} {
    margin-left: rem(-13px);
    padding: 0 rem(26px) rem(10px);

    justify-content: flex-start;
    width: calc(100% + #{rem(26px)});

    overflow-y: hidden;
    overflow-x: auto;
    @include scrollbar-hidden;
  }
}

.item {
  // position: relative;
  // z-index: 0;
  --offset: #{rem(12px)};
  @media #{media("<")} {
    --offset: #{rem(8px)};
  }

  display: flex;
  align-items: center;
  
  white-space: nowrap;
  cursor: pointer;
  letter-spacing: -0.02em;

  @include hoverLine(2px, color(black), var(--offset));
  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: calc(100% + #{rem(12px)});
  //   z-index: 1;
  //   @media #{media("<")} {
  //     top: calc(100% + #{rem(8px)});
  //   }

  //   height: 2px;

  //   opacity: 0;
  //   background-color: color(blue);
  //   transition: $transition;
  // }

  // &:hover {
  //   color: color(blue);

  //   &::before {
  //     opacity: 1;
  //   }
  // }
  
  &.active {

    color: color(blue);

    &::after {
      // opacity: 1;
      background: color(blue);
      transform: scale3d(1, 1, 1);
    }

    .count {
      color: color(blue);
    }
  }

  &:not(:first-child) {
    margin-left: rem(32px);
    @media #{media("<")} {
      margin-left: rem(12px);
    }
  }
}

.text {
  @include fsl(24px, 90%);
  @media #{media("<")} {
    @include fsl(16px);
  }
}

.count {
  margin-left: rem(12px);
  @media #{media("<")} {
    margin-left: rem(4px);
    @include fsl(12px);
  }
  transition: $transition;
  color: color(gray-second);
  @include fsl(18px, 90%);
}