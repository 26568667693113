//fonts
@mixin f-main($font-weight: 700) {
  font-weight: $font-weight;
  font-family: $ff-main;
}

@mixin fsl($font-size, $line-height: null) {
  font-size: unquote('#{$font-size / $fs}rem');
  @if $line-height {
    line-height: $line-height / 100%;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder{
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::placeholder  {
    @content;
  }
}

@mixin scrollbar-hidden() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin cover() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin cropText($lineCount) {
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: $lineCount;
  line-clamp: $lineCount;
}

@mixin hoverLine($size: 2px, $color: color(black), $offset: 2px) {
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    top: calc(100% + $offset);
    left: 0;

    width: 100%;
    height: $size;

    background: $color;
    pointer-events: none;
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
  }

  &:hover::after {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
}