@import 'src/styles/helpers.scss';

.project {
}

.link {
  display: block;
}

.img {
  position: relative;
  z-index: 0;

  display: flex;
  align-items: center;
  
  height: rem(559px);
  // @media #{media("<")} {
  //   height: rem(301px);
  // }
  @media #{media("<")} {
    height: rem(201px);
  }
  border-radius: rem(40px);
  overflow: hidden;
  @media #{media("<")} {
    border-radius: rem(16px);
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1);
    transition: $transition;
  }

  &.active {
    img {
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      // transform: translate(-50%, -50%) scale3d(1.05, 1.05, 1);
    }
    
    .video {
      opacity: 1;
    }
    // img {
    //   display: none;
    // }

    // .video {
    //   display: block;
    // }
  }
}

.video {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;

  width: 101%;
  height: 100%;

  opacity: 0;
  transition: $transition;
  object-fit: cover;
}

.title {
  margin-top: rem(20px);
  @media #{media("<")} {
    margin-top: rem(12px);
    @include fsl(24px);
  }
  letter-spacing: -0.04em;
  @include fsl(40px, 90%);
}

.description {
  margin-top: rem(16px);
  @media #{media("<")} {
    margin-top: rem(8px);
    @include fsl(14px);
  }
  font-weight: 400;
  // @include fsl(18px, 120%);
  font-size: 1.3rem;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: color(black-alt);
}

.list {
  margin-top: rem(20px);
  @media #{media("<")} {
    margin-top: rem(8px);
  }
}