@import 'src/styles/helpers.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  @media #{media("<")} {
    padding: 0 rem(13px) 1px;
    margin-left: rem(-13px);
    
    width: 100vw;
    flex-wrap: nowrap;
    
    overflow-x: auto;
    @include scrollbar-hidden;
  }
}