@import 'src/styles/helpers.scss';

.row {
  display: flex;
  @media #{media("<")} {
    display: block;
  }
}

.col {
  flex-shrink: 0;

  &:nth-child(1) {
    width: calc(#{$col-part-d} * 4);
    @media #{media("<")} {
      width: auto;
    }
  }
  &:nth-child(2) {
    width: calc(#{$col-part-d} * 12 - #{$col-gap-d});
    @media #{media("<")} {
      margin-top: rem(16px);
      width: auto;
    }
  }
}

.title {
  text-align: left;
}

.grid {
  margin-top: rem(60px);

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: rem(38px);
  column-gap: rem(22px);
}

.inner {
  margin-top: rem(80px);
  @media #{media("<")} {
    margin-top: rem(24px);

    display: block;
  }
  display: flex;
  align-items: flex-end;
}

.wrapper {
  margin-left: calc(#{$col-part-d} * 2 + #{$col-gap-d});
  @media #{media("<")} {
    margin-left: 0;
    justify-content: space-between;
  }
  display: flex;
}

.list {
  flex-shrink: 0;
  width: calc(#{$col-part-d} * 3);
  @media #{media("<")} {
    width: 50%;
  }
}

.item {

  display: flex;

  &:not(:first-child) {
    margin-top: rem(20px);
    @media #{media("<")} {
      margin-top: rem(12px);
    }
  }
}

.link {
  display: flex;
  align-items: center;

  @include hoverLine;
}

.text {

  letter-spacing: -0.02em;
  // @include fsl(24px, 90%);
  font-size: 1.8rem;
  line-height: 90%;
  @media #{media("<")} {
    @include fsl(16px);
  }
}

.num {
  margin-left: rem(12px);
  @media #{media("<")} {
    margin-left: rem(8px);
  }
  display: inline-block;

  letter-spacing: -0.02em;
  color: color(gray-second);
  @include fsl(18px, 90%);
  @media #{media("<")} {
    @include fsl(12px);
  }
}

.input {
  padding: 0 rem(24px);
  margin-left: rem(30px);
  margin-bottom: rem(30px);

  width: rem(200px);
  height: rem(68px);

  color: color(black);
  font-weight: 400;
  @include fsl(20px, 120%);
  letter-spacing: -0.02em;
  border-radius: rem(12px);
  background-color: #F5F5F5;
  border: none;
}

.button {

  &.desktop {
    @media #{media("<")} {
      display: none;
    }
  }
  &.mobile {
    margin: rem(50px) rem(12px) 0;

    @media #{media(">")} {
      display: none;
    }
  }
}