@import 'src/styles/helpers.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: rem(20px);
  row-gap: rem(80px);
  @media #{media("<")} {
    // grid-template-columns: 1fr;
    // row-gap: rem(32px);
    display: block;
  }
}

.item {

  @media #{media("<")} {
    &:not(:first-child) {
      margin-top: rem(32px);
    }
  }
}