*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:active, a:focus {outline: none;}
input, textarea {outline:none;}
input:active, textarea:active {outline:none;}
button:active, button:focus {outline: none;}
:focus {outline:none;}
button::-moz-focus-inner {border: 0;}

html {
  // font-size: getVw($fs, 1920px / 100);
  // @each $breakpoint in (1279px,1024px,768px,576px,414px,375px,320px) {
  //   @media (max-width: #{$breakpoint}) {
  //     font-size: getVw($fs, $breakpoint / 100);
  //   }
  // }
  @media (min-width: 320px) {
    font-size: getVw($fs, 375px / 100);
  }
  @each $breakpoint in (375px) {
    @media (min-width: #{$breakpoint}) {
      font-size: getVw($fs, $breakpoint / 100);
    }
  }
  @media (min-width: 1024px) {
    font-size: getVw($fs, 1920px / 100);
  }
  @media (min-width: 1920px) {
    font-size: getVw($fs, 1920px / 100);
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scrollbar-track-color: #EEEEEE;
  scrollbar-darkshadow-color: #EEEEEE;
  scrollbar-3dlight-color: #EEEEEE;
  
  scrollbar-arrow-color: color(black-alt);
  
  scrollbar-highlight-color: color(black-alt);
  scrollbar-shadow-color: color(black-alt);
  scrollbar-face-color: color(black-alt);

  // firefox
  scrollbar-width: thin;
  scrollbar-color: color(black-alt) #EEEEEE;

  // chrome, edge, opera, safari
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #EEEEEE;
  }
  &::-webkit-scrollbar-thumb {
    // margin: 4px 0;
    background-color: color(black-alt);
  }
}

body {
  min-width: 100vw;
  overflow-x: hidden;
  @include f-main();
  color: color(black);
  @include fsl(16px, 100%);
  background-color: color(dark-white);
}

img {
  max-width: 100%;
  max-height: 100%;
}


ul,
ol {
  list-style: none;
}

a {
  color: color(black);
  text-decoration: none;
}

address {
  font-style: normal;
}


input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}