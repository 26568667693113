@import 'src/styles/helpers.scss';

.category {
  display: flex;
  flex-direction: column;
  align-items: center;

  letter-spacing: -0.02em;
  @include fsl(24px, 120%);
  font-weight: 400;
  @media #{media("<")} {
    @include fsl(16px);
    letter-spacing: -0.01em;
  }

  &.horizontal {
    flex-direction: row;

    .text {
      margin-top: 0;
      margin-left: rem(16px);
    }
  }

  &.light {
    color: color(white);

    .num {
      border-color: #292929;
    }

    .text {
      text-align: center;
    }
  }
}

.num {
  --size: #{rem(48px)};
  @media #{media("<")} {
    --size: #{rem(32px)};
  }
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 2px solid color(borders);
}

.text {
  margin-top: rem(16px);
  @media #{media("<")} {
    margin-top: rem(12px);
  }
}