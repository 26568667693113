@import 'src/styles/helpers.scss';

.container {
  padding: rem(100px) 0;
  @media #{media("<")} {
    padding: rem(24px) 0 rem(55px);
  }

  background-color: #101010;
}

.wrapper {
  margin: 0 auto;
  max-width: rem(600px);
  @media #{media("<")} {
    max-width: none;
  }
}

.title {
  margin-top: rem(24px);
  @media #{media("<")} {
    margin-top: rem(16px);
  }
  color: color(white);
  letter-spacing: -0.04em;
  text-align: center;
  @include fsl(200px, 90%);
  @media #{media("<")} {
    @include fsl(64px);
  }
}

.row {
  display: flex;
  gap: rem(22px);
  @media #{media("<")} {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }
  &.center {
    gap: rem(32px);
    justify-content: center;
    @media #{media("<", m)} {
      gap: 0;
    }
  }

  &:not(:first-child) {
    margin-top: rem(22px);
    @media #{media("<", m)} {
      margin-top: 0;
    }
  }
}

.item {
  position: relative;
  z-index: 0;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: rem(427px);
  width: rem(443px);
  @media #{media("<")} {
    width: auto;
    height: rem(287px);
  }
  flex-shrink: 0;
}

.icon {
  margin-bottom: rem(12px);
  @media #{media("<")} {
    margin-bottom: rem(4px);
  }
  display: flex;
  align-items: center;
  justify-content: center;

  svg, img {
    --size: #{rem(210px)};
    @media #{media("<")} {
      --size: #{rem(132px)};
    }
    width: var(--size);
    height: var(--size);
  }
}

.heading {
  margin-bottom: rem(20px);
  @media #{media("<")} {
    margin-bottom: rem(12px);
    max-width: none;
  }
  max-width: rem(336px);

  text-align: center;
  color: color(white);
  @include fsl(40px, 90%);
  letter-spacing: -0.04em;
  @media #{media("<")} {
    @include fsl(26px);
    letter-spacing: -0.02em;
  }
}

.text {
  max-width: rem(331px);
  @media #{media("<")} {
    max-width: rem(450px);
  }
  @media #{media("<", m)} {
    padding: 0 rem(9px);
    max-width: none;
  }
  font-weight: 400;
  text-align: center;
  color: #888888;
  font-size: 1.15rem;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.animation {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
    height: 100%;
  }
}