@import 'src/styles/helpers.scss';

.container {
  padding: rem(40px) 0 rem(60px);
  @media #{media("<")} {
    margin-bottom: rem(24px);
    padding: 0;
  }
  position: relative;
  z-index: 0;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  @media #{media("<")} {
    margin-bottom: rem(12px);
    position: static;
  }
  display: flex;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    @media #{media("<")} {
      display: none;
    }
    width: 100%;
    height: 100%;

    background: rgba(#00000080, 0.25);
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.title {
  margin-bottom: rem(40px);
  @media #{media("<")} {
    margin-bottom: rem(24px);

    color: color(black);

    br {
      display: none;
    }
  }
  text-align: left;
  color: color(white);
}

.wrapper {
  margin-bottom: rem(145px);
  @media #{media("<")} {
    margin-bottom: rem(36px);
    padding-top: rem(8px);

    display: block;

    border-top: 1px solid color(black);
  }
  display: flex;
  justify-content: flex-end;
}

.row {
  display: flex;
}

.col {
  padding-top: rem(28px);
  @media #{media("<")} {
    padding-top: 0;
  }
  flex-shrink: 0;
  width: calc(#{$col-part-d} * 2);
  @media #{media("<")} {
    width: 50%;

    border-top: none;
    color: color(black);
  }
  color: color(white);
  border-top: 1px solid color(white);

  &:nth-child(1) {
    width: calc(#{$col-part-d} * 3);
    @media #{media("<")} {
      width: 50%;
    }
  }

  &.big {
    width: calc(#{$col-part-d} * 3 - #{$col-gap-d});
    @media #{media("<")} {
      width: 50%;
    }
  }
}

.label {
  letter-spacing: -0.02em;
  @include fsl(20px, 100%);
  @media #{media("<")} {
    @include fsl(16px);
    letter-spacing: -0.01em;
  }

  &.alt {

    @media #{media("<")} {
      margin-bottom: rem(24px);
    }
  }
}

.list {

  &.big {
    .item {
      @include fsl(32px, 100%);
      @media #{media("<")} {
        @include fsl(20px);
      }
    }
  }
}

.item {
  letter-spacing: -0.02em;
  @include fsl(24px, 90%);
  @media #{media("<")} {
    @include fsl(20px);
  }
  &:not(:first-child) {
    margin-top: rem(5px);
  }
}

.desktop {
  @media #{media("<")} {
    display: none;
  }
}

.mobile {
  @media #{media(">")} {
    display: none;
  }
}

.inner {
  padding-top: rem(8px);
  margin-bottom: rem(24px);
  
  border-top: 1px solid color(black);
}