@import 'src/styles/helpers.scss';

.intro {
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media #{media("<")} {
    height: auto;
  }
}

.container {
  position: relative;
  z-index: 0;

  height: calc(100vh - #{rem(352px)});
  @media #{media("<")} {
    height: rem(286px);
  }
  display: flex;
  align-items: center;

  overflow: hidden;
}

.frame {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  
  width: 100%;
  height: 100%;

  // transition: 1s $transition-timing-function 3s;
  transition: 1s $transition-timing-function 0.3s;
  background-color: color(white);

  &.active {
    transform: translateY(-100%);
  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;

  // transition: 1s $transition-timing-function 3.1s;
  transition: 1s $transition-timing-function 0.4s;
  transform: scale(1.1);

  &.active {
    transform: none;
  }
}